import type { FeatureFlagStateT } from './types'

export const initialFeatureFlagState: FeatureFlagStateT = {
  featureFlags: {
    expressInTheUK: false,
    release_refer_a_friend_240828: false,
    permission_archive_availability_page_250121: false,
    release_unavailable_educator_note_250121: false,
  },
}
