import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

import AvailabilityForm from './AvailabilityForm/AvailabilityForm'
import BookingsList from './BookingsList'
import SwitchElement from 'src/components/SwitchElement'
import { useFeatureFlagsContext } from 'src/hooks/useFeatureFlags'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'
import { useAvailabilitySchedule } from '../availabilityContext/availabilityContext'
import styles from './styles'
import UnknownForm from './UnknownForm/UnknownForm'

const DayAvailability = () => {
  const { state, selectedDay, updateDayAvailability, isSubmitting } = useAvailabilitySchedule()
  const { featureFlags } = useFeatureFlagsContext()

  const availability = state.availabilityList[selectedDay]
  const bookings = availability?.bookings || []
  const unknown = availability?.unknown
  const showBookingSection = bookings.length > 0
  const showSwitch = !showBookingSection && !unknown
  const isExpress = showBookingSection && state.availabilityList[selectedDay]?.isExpressBooking
  const isSwitchOn = !state.availabilityList[selectedDay]?.inactive

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={StyleSheet.flatten([styles.headerText, !isSwitchOn && styles.headerInactive])}>
          {formatter.longWeekdayAndMonth(selectedDay)}
        </Text>
        {showSwitch ? (
          <SwitchElement
            disabled={isSubmitting}
            isSwitchOn={isSwitchOn}
            onToggle={o => updateDayAvailability({ available: o })}
            testID="switch"
          />
        ) : null}
      </View>
      {unknown ? <UnknownForm onSubmit={updateDayAvailability} /> : null}
      {featureFlags.release_unavailable_educator_note_250121 && showSwitch && !isSwitchOn ? (
        <View style={styles.unavailableNoteContainer}>
          <Text style={styles.unavailableNoteTitle}>{translate(translations.noAvailable)}</Text>
          <Text style={styles.unavailableNoteDescription}>{translate(translations.noAvailableNote)}</Text>
        </View>
      ) : null}
      {showSwitch && isSwitchOn ? <AvailabilityForm /> : null}
      {showBookingSection ? <BookingsList bookings={bookings} isExpress={isExpress} /> : null}
    </View>
  )
}

export default DayAvailability
