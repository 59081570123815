export type FeatureFlagsT = {
  expressInTheUK: boolean
  release_refer_a_friend_240828: boolean
  permission_archive_availability_page_250121: boolean
  release_unavailable_educator_note_250121: boolean
}

export type FeatureFlagStateT = {
  featureFlags: FeatureFlagsT
}

export enum FeatureFlags {
  EXPRESS_UK_FEATURE_TOGGLE = 'expressInTheUK',
  REFER_A_FRIEND_FEATURE_TOGGLE = 'release_refer_a_friend_240828',
  PERMISSION_ARCHIVE_AVAILABILITY_PAGE_250121 = 'permission_archive_availability_page_250121',
  RELEASE_UNAVAILABLE_EDUCATOR_NOTE_250121 = 'release_unavailable_educator_note_250121',
}

export const flagKeys = [
  FeatureFlags.REFER_A_FRIEND_FEATURE_TOGGLE,
  FeatureFlags.EXPRESS_UK_FEATURE_TOGGLE,
  FeatureFlags.PERMISSION_ARCHIVE_AVAILABILITY_PAGE_250121,
  FeatureFlags.RELEASE_UNAVAILABLE_EDUCATOR_NOTE_250121,
]
